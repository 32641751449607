import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

import home from "../assets/images/home.svg"

const BreadcrumbsWrapper = styled.div`
  display: none;
  z-index: 1;
  @media (min-width: 1100px) {
    display: block;
  }
`

const BreadCrumbLinks = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  margin: -20px 20%;
`

const BreadCrumbLink = styled(Link)`
  font-size: 14px;
  color: rgb(255, 196, 0);
  text-decoration: none;
  margin: 0 18px;
`

const Arrow = styled.div`
  position: relative;
  right: 6px;
  top: 0;
  border: solid rgb(255, 196, 0);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 6px;
  transform: rotate(315deg);
`

const Home = styled.img`
  height: 20px;
  margin: -4px 0;
`

const BreadCrumbs = ({ location }: any) => {
  const pathname = location.pathname.replace("/", "").replace("-", " ")
  return (
    pathname && (
      <BreadcrumbsWrapper>
        <BreadCrumbLinks>
          <BreadCrumbLink to="/">
            <Home src={home} alt="Home" />
          </BreadCrumbLink>
          <Arrow />
          <BreadCrumbLink to={location.pathname}>
            {pathname.substring(0, 1).toUpperCase() +
              pathname.substring(1).replace("/", " ")}
          </BreadCrumbLink>
        </BreadCrumbLinks>
      </BreadcrumbsWrapper>
    )
  )
}

export default BreadCrumbs
