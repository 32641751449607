import React from "react"
import { Card, CardImage, CardDescription, Overlay } from "./Card"
import styled from "@emotion/styled"

interface CardListProps {
  cardList: {
    title: string
    cards: {
      nodes: [
        {
          card: {
            title: string
            description: string
            image: string
            type: string
            body: any
          }
        }
      ]
    }
  }
}

type CardProps = {
  image?: string
  title?: string
  body?: any
  card: {
    title: string
    description: string
    image: string
    type: string
    body: any
  }
}

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0, 45%));
  justify-content: center;
  margin: 40px auto;
  max-width: 1300px;
  grid-row-gap: 20px;
  @media (min-width: 400px) {
    grid-template-columns: repeat(auto-fill, minmax(180px, auto));
    grid-gap: 8px;
  }
`

const Cards = ({ cardList }: CardListProps) => {
  if (!cardList) {
    return null
  }
  return (
    <CardContainer>
      {cardList &&
        cardList.cards.nodes.map((card: CardProps, index: number) => {
          return (
            <Card key={index}>
              {card.body.children.map((child: any) => {
                if (child.__typename === "DinoBlock") {
                  return null
                }
                return (
                  <div key={index}>
                    <CardImage
                      src={
                        child.image &&
                        child.image.image &&
                        child.image.image.fluid.src
                      }
                      alt={child.image && child.image.alt}
                    />
                    <CardDescription>
                      {child.image && child.image.alt}
                    </CardDescription>
                    <Overlay />
                  </div>
                )
              })}
            </Card>
          )
        })}
    </CardContainer>
  )
}

export default Cards
