import { Link } from "gatsby";
import React from "react";
import styled from "@emotion/styled";
import SocialIcons from "./Social";
import interac from "../assets/images/interac-it.svg";
import much_better from "../assets/images/much-better-it.svg";
import trustly from "../assets/images/trustly.svg";
import klarna from "../assets/images/klarna.svg";
import eighteen from "../assets/images/eighteen.svg";
import mga from "../assets/images//icons/mga_logo.png";
import responsible from "../assets/images/responsible-gaming.svg";

interface FooterI {
  footerMenu: {
    body: {
      children: any[];
    };
  };
}

const FooterStyle = styled.footer`
  bottom: 0;
  padding: 40px 0;
  background-color: #441152;
  color: #fff;
  margin-top: 120px;
`;

const FooterWrapper = styled.div`
  max-width: 90%;
  margin: auto;
`;

const FooterContainer = styled.footer`
  margin: 20px auto 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 900px;
`;

const LinksColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

const PageLinks = styled(Link)`
  text-decoration: none;
  margin: 0 20px 20px 0;
  color: #fff;
  :hover {
    text-decoration: underline #fff;
  }
`;

const PolicyLinksColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  border-bottom: 1px solid white;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const PolicyLinks = styled.a`
  text-decoration: none;
  margin: 0 10px 10px 0;
  color: #fff;
  :hover {
    text-decoration: underline #fff;
  }
`;

const SocialColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;

const PaymentRow = styled.div`
  display: flex;
  justify-content: center;
`;
const PaymentProviders = styled.img`
  margin: 0 10px 20px 0;
  height: 20px;
`;

const CertRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid white;
`;
const Cert = styled.img`
  margin: 0 10px 20px 0;
  height: 40px;
`;

const CertTitle = styled.h4`
  text-align: center;
`;

const Footer = ({ footerMenu }: FooterI) => {
  const pageLinks = footerMenu?.body?.children?.filter(
    child => child.__typename === "PageLinkBlock"
  );

  const externalLinks = footerMenu?.body.children.filter(
    child => child.__typename === "ExternalLinkBlock"
  );

  return (
    <FooterStyle>
      <FooterWrapper>
        <FooterContainer>
          <LinksColumn>
            {pageLinks?.map(child => {
              return (
                <PageLinks to={child?.page?.path}>
                  {child?.page?.menuTitle}
                </PageLinks>
              );
            })}
          </LinksColumn>
        </FooterContainer>
        <FooterContainer>
          <PolicyLinksColumn>
            {externalLinks?.map(link => {
              return (
                <PolicyLinks
                  href={link.externalLink.url}
                  rel={link.externalLink.rel}
                >
                  {link.externalLink.title}
                </PolicyLinks>
              );
            })}
          </PolicyLinksColumn>
        </FooterContainer>
        <FooterContainer>
          <CertTitle>Safety and Certificates</CertTitle>
          <CertRow>
            <Cert src={eighteen} alt="" />
            <Cert src={mga} alt="mga" />
            <PaymentProviders src={responsible} alt="Responsible Gaming" />
          </CertRow>
        </FooterContainer>
        <FooterContainer>
          <PaymentRow>
            <PaymentProviders src={interac} alt="interac" />
            <PaymentProviders src={much_better} alt="much better it" />
            <PaymentProviders src={trustly} alt="Trustly" />
            <PaymentProviders src={klarna} alt="Klarna" />
          </PaymentRow>
        </FooterContainer>
        <SocialColumn>
          <SocialIcons />
        </SocialColumn>
      </FooterWrapper>
    </FooterStyle>
  );
};

export default Footer;
