import React from "react"

const renderList = (children: any) => {
  return children.map((node: any, index: number) => {
    const item = node.children[0]
    return <li key={index}>{item.text}</li>
  })
}

const List = (props: any) => {
  const { type, children } = props
  if (type === "numbered-list") {
    return <ol>{renderList(children)}</ol>
  }
  if (type === "bulleted-list") {
    return <ul>{renderList(children)}</ul>
  }

  return null
}

export default List
