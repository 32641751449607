import React from "react"
import Marks from "./Marks"

const Quote = (props: any) => {
  return (
    <q>
      <Marks {...props} />
    </q>
  )
}

export default Quote
