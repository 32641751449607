import React from "react";
import { richTextRenderer } from "@dinotechltd/gatsby";
import Card from "./Cards";
import PageImage from "./PageImage";
import ContentBlock from "./ContentBlock";
import { CookieDeclaration } from "@dinotechltd/components";

const Renderer = richTextRenderer({
  renderContentBlock: (props: any) => {
    return <ContentBlock {...props} />;
  },
  renderCookieDeclarationBlock: () => <CookieDeclaration />,
  renderSeoBoxBlock: ({ body }) => {
    return <Renderer data={body} />;
  },
  renderCardBlock: (props: any) => {
    return <PageImage image={props} />;
  },
  renderCardListBlock: props => {
    return <Card cardList={props} />;
  },
});

export default Renderer;
