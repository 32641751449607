import React from "react"
import Marks from "./Marks"

const Paragraph = (props: any) => {
  return (
    <p>
      <Marks {...props} />
    </p>
  )
}

export default Paragraph
