import { RouteUpdateArgs, GatsbyBrowser, PluginOptions } from 'gatsby';
import { trigger, pageViewEvent, cookiesAcceptedEvent, installer, Events } from '@dinotechltd/events';
import loadScript from 'simple-load-script';
import { deferUntilScrolled } from './defer';

declare global {
  interface Window {
    Cookiebot?: any;
  }
}

export const COOKIES_ACCEPTED_NAME = 'cookiesAccepted';

export async function loadCookiebot(cookiebotId: string) {
  if (typeof window.Cookiebot === 'undefined') {
    await loadScript(
      `https://consent.cookiebot.com/uc.js?cbid=${cookiebotId}`
    );
  }
  return window.Cookiebot;
}

export async function acceptCookies(cookiebotId: string) {
  const cookiebot = await loadCookiebot(cookiebotId);
  localStorage.setItem(COOKIES_ACCEPTED_NAME, 'true');
  trigger(cookiesAcceptedEvent);
  cookiebot.submitCustomConsent(true, true, true);
}

export function cookiesAccepted() {
  if (typeof window !== 'undefined') {
    return window.localStorage.getItem(COOKIES_ACCEPTED_NAME) === 'true';
  } else {
    return true;
  }
}

// https://github.com/benjaminhoffman/gatsby-plugin-segment-js/pull/19/files
export function installCookiebot(cookiebotId: string) {
  deferUntilScrolled(() => loadCookiebot(cookiebotId))
}

let dependeciesInstalled = false;

async function ensureDependeciesAreInstalled(installer: () => Promise<void>) {
  if (!dependeciesInstalled) {
    dependeciesInstalled = true;
    await installer();
  }
}

const install = installer('Cookiebot');

export function createRouteUpdateForTracking(installer: () => Promise<void>): GatsbyBrowser['onRouteUpdate'] {
  let sentInitialPageView = false;

  // Only enable google analytics for visitors who accept cookies
  install(Events.COOKIES_ACCEPTED, async () => {
    if (!sentInitialPageView) {
      sentInitialPageView = true;
      await ensureDependeciesAreInstalled(installer);
      trigger(pageViewEvent());
    }
  });

  // Track analytics on every route page. This code is more or less a
  // copy of gatsby-google-analytics but with a cookiebot guard.
  return async ({ location }: RouteUpdateArgs, pluginOptions: PluginOptions) => {
    if (!cookiesAccepted()) {
      return null;
    }

    sentInitialPageView = true;
    await ensureDependeciesAreInstalled(installer);

    const sendPageView = () => {
      const path = location
        ? location.pathname + location.search + location.hash
        : undefined;
      trigger(pageViewEvent(path));
    };

    // Minimum delay for reactHelmet's requestAnimationFrame
    const delay = Math.max(32, (pluginOptions.pageTransitionDelay as number | undefined) || 0);
    setTimeout(sendPageView, delay);

    return null;
  };
}
