import facebook from "../assets/images/facebook-white.svg"
import instagram from "../assets/images/instagram_white.svg"
import twitter from "../assets/images/twitter.svg"

import styled from "@emotion/styled"

import React from "react"

const Icon = styled.img`
  height: 24px;
  display: block;
  margin: 0 16px 10px 0;
  @media (min-width: 800px) {
    height: 48px;
    margin: 0 16px 0 0;
  }
`
const Container = styled.div`
  display: flex;
  justify-self: flex-end;
  flex-direction: row;
  margin-right: -16px;
  @media (min-width: 800px) {
    margin-right: 16px;
    flex-direction: row;
  }
`
const SocialIcons = () => (
  <Container>
    <a
      href="https://twitter.com/MountGold"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Icon src={twitter} alt="Twitter" />
    </a>
    <a
      href="https://www.facebook.com/mountgoldcasino/"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Icon src={facebook} alt="Facebook" />
    </a>
    <a
      href="https://www.instagram.com/mountgoldcasino/"
      rel="noopener noreferrer"
      target="_blank"
    >
      <Icon src={instagram} alt="Instagram" />
    </a>
  </Container>
)

export default SocialIcons
