import styled from "@emotion/styled";

export const Card = styled.div`
  background: rgba(110, 8, 90, 0.5);
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(1 1fr);
  justify-items: self-start;
  justify-self: center;
  border-radius: 4px;
  backdrop-filter: blur(30px);
  box-sizing: border-box;
  @media (min-width: 800px) {
  }
`;

export const CardTitle = styled.h2`
  color: white;
`;

export const CardImage = styled.img`
  position: relative;
  height: 130px;
  justify-self: center;
  align-self: center;
  margin-bottom: -6px;
  text-align: center;

  @media (min-width: 400px) {
    height: 168px;
  }
`;

export const CardDescription: any = styled.p`
  z-index: 30;
  position: absolute;
  bottom: -15px;
  color: white;
  font-size: 14px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  margin: auto;
  @media (min-width: 400px) {
    max-width: 168px;
  }
`;

export const Overlay = styled.div`
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 20%; /* Full height (cover the whole page) */
  top: 80%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(68, 17, 82, 0.6); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
`;
