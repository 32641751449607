import React, { FC, useCallback, useState } from "react"
import { Link, GatsbyLinkProps } from "gatsby"
import { updateUrlParameter } from "@dinotechltd/paths"
import { useEventListener, Events } from "@dinotechltd/events"

const UpdatableLink: FC<GatsbyLinkProps<any>> = ({ children, ...props }) => {
  const [updated, setUpdated] = useState(false)
  const handler = useCallback(() => setUpdated(true), [setUpdated])

  useEventListener(Events.SERVICE_WORKER_UPDATED_EVENT, handler)

  const updateUrl = updateUrlParameter(
    props.to,
    "__updated",
    String(new Date().getTime())
  )

  return updated && props.to.startsWith("/") ? (
    // @ts-ignore
    <Link
      {...props}
      to={updateUrl}
      onClick={e => {
        if (
          e.button === 0 && // ignore right clicks
          !props.target && // let browser handle "target=_blank"
          !e.defaultPrevented && // onClick prevented default
          !e.metaKey && // ignore clicks with modifier keys...
          !e.altKey &&
          !e.ctrlKey &&
          !e.shiftKey
        ) {
          e.preventDefault()
          window.location.href = updateUrl
        }

        return true
      }}
    >
      {children}
    </Link>
  ) : (
    // @ts-ignore
    <Link {...props}>{children}</Link>
  )
}

export default UpdatableLink
