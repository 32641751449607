import styled from "@emotion/styled"
import React from "react"

const CssCloud = styled.div`
  margin: 20px auto;
  width: 200px;
  position: relative;
  @media (min-width: 800px) {
    margin: 140px 400px;
  }
`

const One = styled.div`
  background-color: rgba(250, 250, 250, 0.99);
  position: absolute;
  width: 68px;
  height: 65px;
  top: -20px;
  left: 50px;
  border-radius: 50%;
`
const Two = styled.div`
  background-color: rgba(250, 250, 250, 0.99);
  position: absolute;
  width: 50px;
  height: 50px;
  top: -10px;
  left: 100px;
  border-radius: 50%;
`
const Three = styled.div`
  background-color: rgba(250, 250, 250, 0.99);
  position: absolute;
  top: 10px;
  left: 28px;
  width: 150px;
  height: 45px;
  border-radius: 35px;
  text-align: center;
  font-weight: 400;
  padding: 1px;
  color: purple;
`

const CssClouds = styled.div`
  margin: 20px auto;
  width: 200px;
  position: relative;
  @media (min-width: 800px) {
    margin: 140px 800px;
  }
`

const OneC = styled.div`
  background-color: rgba(250, 250, 250, 0.99);
  position: absolute;
  width: 68px;
  height: 65px;
  top: -20px;
  left: 50px;
  border-radius: 50%;
`
const TwoC = styled.div`
  background-color: rgba(250, 250, 250, 0.99);
  position: absolute;
  width: 50px;
  height: 50px;
  top: -10px;
  left: 100px;
  border-radius: 50%;
`
const ThreeC = styled.div`
  background-color: rgba(250, 250, 250, 0.99);
  position: absolute;
  top: 10px;
  left: 28px;
  width: 150px;
  height: 45px;
  border-radius: 35px;
  text-align: center;
  font-weight: 400;
  padding: 1px;
  color: purple;
`

const Cloudtwo = styled.div`
  width: 200px;
  position: relative;
  margin-top: 150px;
  animation: infinite;
  animation-name: run;
  -webkit-animation: run 50s linear infinite;
  -moz-animation: run 50s linear infinite;
  animation: run 50s linear infinite;

  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.8);
  @keyframes run {
    0% {
      margin-left: -100px;
    }
    100% {
      margin-left: 120%;
    }
  }
`

const C2 = styled.div`
  background-color: rgba(250, 250, 250, 0.99);
  position: absolute;
  width: 68px;
  height: 65px;
  top: -20px;
  left: 50px;
  border-radius: 50%;
`
const C21 = styled.div`
  background-color: rgba(250, 250, 250, 0.99);
  position: absolute;
  width: 50px;
  height: 50px;
  top: -10px;
  left: 100px;
  border-radius: 50%;
`
const C23 = styled.div`
  background-color: rgba(250, 250, 250, 0.99);
  position: absolute;
  top: 10px;
  left: 28px;
  width: 150px;
  height: 45px;
  border-radius: 35px;
  text-align: center;
  font-weight: 400;
  padding: 1px;
  color: purple;
`

const Cloudthree = styled.div`
  width: 200px;
  position: relative;
  margin: 175px;
  animation: infinite;
  animation-name: run;
  -webkit-animation: run 40s linear infinite;
  -moz-animation: run 40s linear infinite;
  animation: run 40s linear infinite;

  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(1.2);
  @keyframes run {
    0% {
      margin-left: -100px;
    }
    100% {
      margin-left: 120%;
    }
  }
`

const C3 = styled.div`
  background-color: rgba(250, 250, 250, 0.99);
  position: absolute;
  width: 68px;
  height: 65px;
  top: -20px;
  left: 50px;
  border-radius: 50%;
`
const C31 = styled.div`
  background-color: rgba(250, 250, 250, 0.99);
  position: absolute;
  width: 50px;
  height: 50px;
  top: -10px;
  left: 100px;
  border-radius: 50%;
`
const C33 = styled.div`
  background-color: rgba(250, 250, 250, 0.99);
  position: absolute;
  top: 10px;
  left: 28px;
  width: 150px;
  height: 45px;
  border-radius: 35px;
  text-align: center;
  font-weight: 400;
  padding: 1px;
  color: purple;
`

export const Cloud = ({ children }: any) => (
  <CssCloud>
    <One></One>
    <Two></Two>
    <Three> {children}</Three>
  </CssCloud>
)

export const CloudT = ({ children }: any) => (
  <CssClouds>
    <OneC></OneC>
    <TwoC></TwoC>
    <ThreeC> {children}</ThreeC>
  </CssClouds>
)

export const CloudTh = ({ children }: any) => (
  <Cloudthree>
    <C3></C3>
    <C31></C31>
    <C33> {children}</C33>
  </Cloudthree>
)
