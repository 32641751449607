import React, { FC } from "react"
import RenderRichText from "./RenderRichText"

type Props = {
  content: {
    path: string
    isPrivate: Boolean
    description: string
    keywords: string
    robots?: string
    body: {
      children: any
    }
  }
  search?: any
}

const RichText: FC<Props> = ({ content, ...props }) => {
  // The content is not available in the app shell
  if (!content) {
    return null
  }

  const { isPrivate, body } = content

  if (isPrivate) return null

  if (!body || !body.children) return null

  return <RenderRichText data={body} {...props} />
}

export default RichText
