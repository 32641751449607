import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import RichTextComponent from "../components/RichText";
import { makeLocalizedUrl } from "@dinotechltd/paths";
import Layout from "../components/Layout";

type Props = {
  path: string;
  pageContext: {
    locale: string;
    defaultLocale: string;
    localizedPath: string;
  };
  location: any;
  data: {
    page: {
      title: string;
      path: string;
      isPrivate: Boolean;
      description: string;
      keywords: string;
      robots?: string;
      createdAt: string;
      updatedAt: string;
      body: {
        children: any;
      };
      requiresAuthentication?: boolean;
      search?: any;
    };
    allPage: {
      nodes: [
        {
          locale: string;
          path: string;
        }
      ];
    };
    allMenu: {
      nodes: any[];
    };
  };
};

const Page = ({ data, pageContext, location }: Props) => {
  const { page, allMenu } = data;
  const { locale, defaultLocale } = pageContext;
  const canonicalUrl = makeLocalizedUrl(
    process.env.GATSBY_BASE_URL!,
    locale,
    defaultLocale,
    page.path
  );
  function convertLocale(locale: string) {
    return locale.replace("_", "-");
  }
  //const canonicalUrl = process.env.GATSBY_BASE_URL! + usePath(page.path);
  //   const { submitLayout } = useLayout()
  //   useEffect(() => submitLayout(defaultLayout), [])

  const contents = (
    <>
      <Helmet>
        <html lang={convertLocale(locale)} />
        <title>{page.title}</title>
        {page.description && (
          <meta name="description" content={page.description} />
        )}
        {page.keywords && <meta name="keywords" content={page.keywords} />}
        {process.env.GATSBY_ACTIVE_ENV == "production" && page.robots && (
          <meta name="robots" content={page.robots} />
        )}
        <link rel="canonical" key={locale} href={canonicalUrl} />
        {data.allPage.nodes.map(({ locale, path }) => (
          <link
            rel="alternative"
            hrefLang={locale === defaultLocale ? "x-default" : locale}
            key={locale}
            href={makeLocalizedUrl(
              process.env.GATSBY_BASE_URL!,
              locale,
              defaultLocale,
              path
            )}
          />
        ))}
        <script type="application/ld+json" id="ld-webpage">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": `${canonicalUrl}#webpage`,
            url: canonicalUrl,
            inLanguage: locale,
            name: page.title,
            // description,
            isPartOf: `${process.env.GATSBY_BASE_URL}#website`,
            datePublished: page.createdAt,
            dateModified: page.updatedAt,
          })}
        </script>
      </Helmet>
      <Layout location={location} content={page} menus={allMenu?.nodes}>
        <RichTextComponent search={page.search} content={page} />
      </Layout>
    </>
  );

  return contents;
};

export default Page;

export const query = graphql`
  query($contentId: String!, $locale: String!) {
    page(contentId: { eq: $contentId }, locale: { eq: $locale }) {
      createdAt
      updatedAt
      path
      ...Page
    }
    allPage(filter: { contentId: { eq: $contentId } }) {
      nodes {
        locale
        path
      }
    }
    allMenu {
      nodes {
        ...Menu
      }
    }
  }
`;
