import styled from "@emotion/styled"

export const TriangleTrees = styled.div`
  position: relative;
  margin: 250px 750px;
`

export const TreeOne = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  margin: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 25px solid #2cd325;
`
export const TreeTwo = styled.div`
  position: absolute;
  top: 5px;
  left: 50px;
  width: 0;
  height: 0;
  margin: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 25px solid #2cd325;
`
export const TreeThree = styled.div`
  position: absolute;
  top: 35px;
  left: 28px;
  width: 0;
  height: 0;
  margin: 10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 25px solid #2cd325;
`
