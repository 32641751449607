// https://github.com/benjaminhoffman/gatsby-plugin-segment-js/pull/19/files
export function deferUntilScrolled(f: () => Promise<any>) {
  let loaded = false;
  let loading = false;

  const install = () => {
    if (!loaded && !loading) {
      loading = true;
      const loader = () =>
        f().then(() => {
          loaded = true;
          loading = false;
        });
      setTimeout(
        function () {
          "requestIdleCallback" in window
            // @ts-ignore
            ? window.requestIdleCallback(loader)
            : loader();
        }, 1000
      );
    }
  }
  window.addEventListener('scroll', install, { once: true });
}